import styled from '@emotion/styled';
import React, { Component } from 'react';
import background from '../assets/images/cover_aboutpage.gif';
import windows from '../assets/images/windows.png';
import AboutMeContent from '../components/about/about-me-content';
import Layout from '../components/layout';


const Background = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100vw * 1.5);
    z-index: 0;
    /* background from https://imgur.com/gallery/SELjK */
    background-image: url(${background});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    animation: dawn 9000ms ease-in-out;
`

const Window = styled.div`
    position: absolute;
    width: 100%;
    height: calc(100vw *1.5);
    z-index: 1;
    background-image: url(${windows});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
`
const TextBubbleWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 5fr 1fr;
    grid-template-areas:
     "story null"
     "about about";
    position: absolute;
    z-index:2;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vw *1.5);
`

const AboutMeDesktop = styled.div`
    grid-area: story;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    margin-right: -17vw;
    margin-top: 34rem;
    
    a {
        justify-content: flex-end;
    }
    
    * {
        max-width: 400px;
    }
    
    @media (max-width: 721px) {
        justify-content: center;
        grid-area: about;
        margin: 2rem 4rem 0 4rem;
        text-align: center;
        a {
            justify-content: center;
        }
    }

    @media (max-width: 446px) {
        margin: 2rem 1rem 0 1rem;
    }
    `

class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        console.log(this.ref.current)
        this.ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
    }

    render() {
        return (
            <Layout>
                <Background className="load">
                </Background>
                <Window></Window>
                <TextBubbleWrapper >
                    <AboutMeDesktop ref={this.ref}>
                        <AboutMeContent></AboutMeContent>
                    </AboutMeDesktop>
                </TextBubbleWrapper>
            </Layout>
        )
    }
}

export default AboutPage;
