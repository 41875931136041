import styled from '@emotion/styled';
import React, { Component } from 'react';

const StyledLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
  color: white;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 1px 2px rgba(242, 47, 70, 0.9),
    -2px -1px rgba(0, 255, 255, 0.5);
  &:hover {
    animation: glitch 100ms 1 alternate;
  }
`

export default class AboutMeContent extends Component {
  render() {
    return (
      <div>
        <span
          style={{
            color: 'white',
            fontFamily: 'monospace',
            wordBreak: 'break-word',
          }}
        >
          <strong>Vagabond</strong>
          <br />
          <small>/ˈvaɡəbɒnd/</small>
          <br />A person on a trip of indeterminate destination and/or length of
          time.
          <br />(<a href="https://en.wiktionary.org/wiki/vagabond">Wikipedia</a>
          )<br />
          <br />
          <hr />
          <br />
          Web technologies change day in, day out. To stay relevant and optimize
          my "time to first ship", I decided to <i>vagabondize</i> my approach
          to software development:{' '}
          <strong>never settle for a framework or technology</strong>.<br />
          <br />
          To ship a functional product fast, I combine aesthetic sensibilities
          and problem solving skills with feature priotizations that respect the
          constraints of the project. If you think I can help you in your
          current project, let's get in touch.
        </span>

        <br />
        <br />

        <StyledLink
          href="https://stackoverflow.com/users/6701831"
          style={{ color: 'white', fontFamily: 'monospace' }}
        >
          Stackoverflow
        </StyledLink>
        <StyledLink
          href="https://www.linkedin.com/in/linh-phung/"
          style={{ color: 'white', fontFamily: 'monospace' }}
        >
          LinkedIn
        </StyledLink>
        <StyledLink
          href="https://github.com/code-vagabond"
          style={{ color: 'white', fontFamily: 'monospace' }}
        >
          Github
        </StyledLink>
        {/* <StyledLink
          href={CV}
          style={{ color: 'white', fontFamily: 'monospace' }}
          target="_blank"
        >
          CV
        </StyledLink> */}

        <br />
        <br />

        {/* <StyledLink
                            href="https://calendly.com/linh-phung"
                            style={{ color: 'white', fontFamily: 'monospace' }}>
                            Call me
                        </StyledLink> */}
      </div>
    )
  }
}
